const data = [
  {
    name: "ACSL Theory",
    description: "Grades 5-10",
    code: "acsltheory-grp",
    url: "http://localhost:5000/api/event_schedules/course_grp/acsltheory-grp",
  },
  // {
  //   name: "ACSL Coding",
  //   code: "acslcoding-grp",
  //   description: "Grades 5-10",
  //   url: "http://localhost:5000/api/event_schedules/course_grp/acslcoding-grp",
  // },
];

export default data;
