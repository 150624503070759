import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import YAML from "js-yaml";
import "./leftmenu.scss";

const Leftmenu = ({ Url, Type, Theme }) => {
  const [menu, setMenu] = useState(null);
  const [theme, setTheme] = useState("#4B606B");

  const fetchData = useCallback(async () => {
    if (Theme) {
      setTheme(Theme);
    }
    if (Type === "yml") {
      let res = await fetch(Url);
      let data = await res.text();
      let json = YAML.load(data);
      setMenu(json);
    }
    if (Type === "json") {
      let res = await fetch(Url);
      let json = await res.json();
      setMenu(json);
    }
  }, [Theme, Type, Url]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {menu ? (
        <nav className="side-navigation leftmenu shadow rounded ">
          <h2 className="menu-title" style={{ backgroundColor: theme }}>
            <span>Our Courses</span>
            <a href="#sideMenu">
              <i className="fa-solid fa-angle-down"></i>
            </a>
          </h2>
          <ul id="sideMenu " className="side-menu">
            {menu.length > 0 &&
              menu.map((item) => {
                if (item.sub_menus.length < 1)
                  return (
                    <li key={item.text}>
                      <Link to={`.${item.link}`} className="text-secondary">
                        {item.text}
                      </Link>
                    </li>
                  );
                else {
                  return (
                    <div className="dropdown" key={item.text}>
                      <button
                        className="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {item.text}
                      </button>
                      <ul className="dropdown-menu">
                        {item.sub_menus.map((item) => (
                          <li key={item.text}>
                            <Link to={`.${item.link}`}>{item.text}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                }
              })}
          </ul>
        </nav>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Leftmenu;
