import React, { useEffect, useState } from 'react'
import json from './data'
const Instructor = () => {
    const [tableData, setTableData] = useState(null);
    useEffect(() => {
        setTableData(json);
    }, [])
    return (
        <div>
            {

                tableData &&
                <>
                    {
                        tableData.title &&
                        <h3 className='fw-5 text-center text-secondary'>{tableData.title}</h3>
                    }
                    {
                        tableData.content &&
                        <table class="table text-center">
                            <thead>
                                <tr>

                                    <th className="fw-semibold  text-black">Instructor</th>
                                    <th className=" fw-semibold text-black">Profile</th>
                                    <th style={{ width: "600px" }} className="fw-semibold  text-black">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    tableData.content.length > 0 && tableData.content.map((item) => (
                                        <tr>
                                            <td style={{ fontSize: "15px" }}>{item.instructor}</td>
                                            <td><img src={item.profile} alt={item.instructor} className='img img-fluid' width={"150px"} style={{ backgroundSize: "object-fit" }} /></td>
                                            <td style={{ fontSize: "14px" }}>{item.description}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>

                        </table>
                    }
                </>
            }
        </div >
    )
}

export default Instructor
