const data = {
  title: "",
  description: "",
  font_color: "",
  bg_color: "#f1f1f1",
  form_fields: [
    {
      label: "Student First Name",
      placeholder: "First Name",
      type: "text",
      name: "first_name",
      required: true,
    },
    {
      label: "Student Last Name",
      placeholder: "Last Name",
      type: "text",
      name: "last_name",
      required: false,
    },
    {
      label: "Student Email",
      placeholder: "Student Email",
      type: "email",
      name: "email",
      required: true,
    },
    {
      label: "Password",
      placeholder: "Password",
      type: "Password",
      name: "password",
      required: true,
    },
    {
      label: "Grade",
      placeholder: "Grade",
      type: "text",
      name: "grade",
      required: false,
    },
    {
      label: "School Name",
      placeholder: "School Name",
      type: "text",
      name: "school_name",
      required: false,
    },
    {
      label: "Billing Number",
      placeholder: "Billing Number",
      type: "text",
      name: "billing_number",
      required: false,
    },
    {
      label: "Additional Number",
      placeholder: "Additional Number",
      type: "text",
      name: "additonal_number",
      required: false,
    },
    {
      label: "Student Phone Number",
      placeholder: "Student Phone Number",
      type: "text",
      name: "student_phone_number",
      required: true,
    },
    {
      label: "Address",
      placeholder: "Address",
      type: "text",
      name: "address",
      required: false,
    },
    {
      label: "Billing Name",
      placeholder: "Billing Name",
      type: "text",
      name: "billing_name",
      required: false,
    },
    {
      label: "Billing Email 1",
      placeholder: "Billing Email 1",
      type: "email",
      name: "billing_email_1",
      required: false,
    },
    {
      label: "Additional Email",
      placeholder: "Additional Email",
      type: "email",
      name: "additonal_email",
      required: false,
    },
    {
      label: "Father's Name",
      placeholder: "Father's Name",
      type: "text",
      name: "father_name",
      required: false,
    },
    {
      label: "Father's Email",
      placeholder: "Father's Email",
      type: "email",
      name: "father_email",
      required: false,
    },
    {
      label: "Father's Mobile Number",
      placeholder: "Father's Mobile Number",
      type: "number",
      name: "father_mobile_no",
      required: false,
    },
    {
      label: "Mother's Name",
      placeholder: "Mother's Name",
      type: "text",
      name: "mother_name",
      required: false,
    },
    {
      label: "Mother's Email",
      placeholder: "Mother's Email",
      type: "email",
      name: "mother_email",
      required: false,
    },
    {
      label: "Mother's Mobile Number",
      placeholder: "Mother's Mobile Number",
      type: "number",
      name: "mother_mobile_no",
      required: false,
    },
    {
      label: "Country of origin",
      placeholder: "Mother's Mobile Number",
      type: "select",
      options: [
        { name: "India", value: "india" },
        { name: "America", value: "america" },
      ],
      required: false,
    },
  ],
  submit_btn: {
    text: "Register",
    font_color: "#fff",
    bg_color: "rgb(75,96,107)",
    api: "",
  },
};

export default data;
