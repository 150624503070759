const data = {
  title: "",
  content: [
    {
      instructor: "Sharad Goyal",
      profile:
        "https://vss-prod.s3.us-east-1.amazonaws.com/questions/624493f889432-1648661496.jpg",
      description: "",
    },
    {
      instructor: "Vandana Goyal",
      profile:
        "https://vss-prod.s3.us-east-1.amazonaws.com/questions/6244945ec27cd-1648661598.jpg",
      description:
        "Vandana Goyal is a dedicated and passionate educator with over two decades of teaching experience. Since 1998, she has been actively engaged in teaching primary math students and computer students. Vandana possesses a unique ability to connect with younger kids, fostering a nurturing and supportive learning environment. Her passion for teaching is evident in her innovative approaches and commitment to ensuring that every student receives a quality education. Vandana's extensive experience and genuine enthusiasm make her a beloved teacher, leaving a lasting impact on the young minds she guides. Parents can further attest to Vandana's exceptional teaching abilities through the positive reviews available on Google.",
    },
    {
      instructor: "Utkarsh Goyal",
      profile:
        "https://s3.amazonaws.com/cdn.virtusasystems.com/subscriber/elearning/tacad/utkarsh+goyal.jpeg",
      description:
        "Utkarsh Goyal has established himself as a prominent figure in mathematics education. As a two-time USA(J)MO qualifier, Utkarsh has showcased exceptional problem-solving skills and mathematical prowess on a national level. Additionally, his impressive feat of qualifying for the American Invitational Mathematics Examination (AIME) four times highlights consistent excellence in the subject. Utkarsh's achievements testify to his deep understanding of advanced mathematical concepts and his ability to inspire and guide students toward success in mathematics.",
    },
    {
      instructor: "Adya Garg",
      profile: "",
      description:
        "Adya Garg, along with a team of talented individuals, including Jessica Chen, Anvi Borkar, Sameeha Shaik, Aaditya Kasture, Kaitlin Puffenberger, and Avni Garg, has authored a remarkable Geometry book. This comprehensive resource, meticulously written and edited by the team, offers in-depth explanations of geometric concepts for students and educators. Adya's leadership, combined with the expertise and contributions of the co-authors/editors, has resulted in a valuable book that serves as a guide for mastering Geometry. Their collaborative efforts have produced a resource that empowers learners to understand and excel in the study of Geometry.",
    },
  ],
};

export default data;
