import React, { useEffect, useState, useCallback } from 'react'
import YAML from "js-yaml";
let site_config_file;
const Slider = ({ site_config_url, Theme, Code }) => {
    const [sliderData, setSliderData] = useState(null);
    const [theme, setTheme] = useState(null);

    const fetchData = useCallback(async () => {
        if (Code) {
            let site_config = await fetch(site_config_file);
            if (!site_config.ok) {
                return;
            }
            site_config = await site_config.text();
            site_config = YAML.load(site_config).site_config;
            let component = site_config.components.find(
                (item) => item.code === Code
            );

            if (!component) {

                return;
            }

            if (component && !component.auth) {
                if (component.type === "json") {
                    let res = await fetch(component.slider_url);
                    let json = await res.json();

                    setSliderData(json)
                }
                if (component.type === "yml" || component.type === "yaml") {
                    let res = await fetch(component.slider_url);
                    let data = await res.text();
                    let json = YAML.load(data);
                    setSliderData(json)
                }
            }
            if (component && component.auth) {

            }


        }
    }, [Code])
    useEffect(() => {

        if (Theme) {
            setTheme(Theme);
        }
        if (site_config_url) {
            site_config_file = site_config_url;
            fetchData();
        }
    }, [fetchData, Theme, site_config_url]);


    return (
        <>
            {
                sliderData ?
                    <div className="container-fluid border " style={{ overflowX: 'hidden' }}>
                        <h3 className='fs-4 text p-3' style={{ color: theme }}>{sliderData.heading}</h3>
                        <div className=" row justify-content-center align-items-center">
                            <div className="col-md-6">
                                {
                                    sliderData.content.map((item, index) => (
                                        <p key={index} className=''>
                                            <i className="fa-solid fa-circle" style={{ fontSize: '12px', color: '#CCCCCC' }}></i> <span className='fw-semibold p-1' style={{ color: theme, cursor: 'pointer' }}>{item.heading} </span> {item.description}
                                        </p>
                                    ))
                                }
                            </div>
                            <div className="col-md-5">
                                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="false">
                                    <div className="carousel-inner">
                                        {
                                            sliderData.content.map((item, index) => (
                                                <div key={index} className={index ? 'carousel-item' : 'carousel-item active'}>
                                                    <img src={item.img} className="d-block w-100 img-fluid" style={{ width: '100%', height: "500px", objectFit: "contain", backgroundPosition: 'center' }} alt={item.alt} />
                                                </div>
                                            )
                                            )

                                        }
                                    </div>
                                    <button className="carousel-control-prev text-dark fs-3 " type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev" style={{ transform: 'translateX(-60px)' }}>
                                        <i className="fa-solid fa-chevron-left"></i>

                                    </button>
                                    <button className="carousel-control-next text-dark fs-3 " type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next" style={{ transform: 'translateX(60px)' }}>
                                        <i className="fa-solid fa-chevron-right"></i>

                                    </button>
                                </div>
                            </div>


                        </div>

                    </div>


                    :
                    <>
                        data not found
                    </>
            }

        </>
    )
}

export default Slider
