import React from 'react'
import { Outlet } from 'react-router'
import Topmenu from "./menuui/Topmenu/Topmenu";
import Leftmenu from "./menuui/Leftmenu/Leftmenu";
import Footer from "./menuui/Footer/Footer";
import leftmenu_yml from "./data/yml/leftmenu.yml";
import topmenu_yml from "./data/yml/topmenu.yml";
import footer_yml from "./data/yml/footer.yml";

const MainComponent = () => {
    return (
        <div>
            <Topmenu
                Url={`${window.location.protocol}//${window.location.host}${topmenu_yml}`}
                Type="yml"
                Theme="#4B606B"
            />
            <div className="container-fluid my-4">
                <div className="row p-2 ">
                    <div className="col-md-2 mb-3">
                        <Leftmenu
                            Url={`${window.location.protocol}//${window.location.host}${leftmenu_yml}`}
                            Type="yml"
                        />
                    </div>

                    <div className="col-md-10 " style={{ minHeight: "500px" }}>
                        <Outlet />
                    </div>
                </div>
            </div>
            <Footer
                Url={`${window.location.protocol}//${window.location.host}${footer_yml}`}
                Type="yml"
                Theme="#4B606B"
            />
        </div>
    )
}

export default MainComponent
