import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./topmenu.scss";
import YAML from "js-yaml";

const Topmenu = ({ Url, Type, Theme }) => {
  const [header, setHeader] = useState(null);
  const navigate = useNavigate();
  const [theme, setTheme] = useState("#4B606B");
  const fetchData = useCallback(async () => {
    if (Theme) {
      setTheme(Theme);
    }
    if (Type === "yml") {
      let res = await fetch(Url);
      let data = await res.text();
      let json = YAML.load(data);
      setHeader(json);
    }
    if (Type === "json") {
      let res = await fetch(Url);
      let json = await res.json();
      setHeader(json);
    }
  }, [Theme, Type, Url]);

  const clickHandler = (e, a) => {
    e.preventDefault();
    if (a.redirect) {
      window.open(a.link, "_blank");
      return;
    }
    navigate(`.${a.link}`);
  };
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {header ? (
        <header
          className="top-header topmenu sticky-top shadow"
          style={{ fontSize: "14px" }}
        >
          <div className="top-menu">
            <nav
              className="navbar navbar-expand-lg "
              style={{ backgroundColor: theme }}
            >
              <div className="container-fluid">
                <Link
                  className="navbar-brand d-flex flex-row align-items-center"
                  to={`.${header.logo.link}`}
                  onClick={(e) => {
                    clickHandler(e, header.logo);
                  }}
                >
                  <img
                    className="img-fluid company-logo"
                    alt=""
                    src={header.logo.img}
                    style={{
                      width: header.logo.width,
                      height: header.logo.height,
                    }}
                  />
                  <h1 className="text-white fs-5 ms-2">{header.brand}</h1>
                </Link>

                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#topMenu"
                  aria-controls="topMenu"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div id="topMenu" className="collapse navbar-collapse ms-3">
                  <ul className="navbar-nav default-menu me-auto">
                    {header.links.map((link) => (
                      <li className="nav-item" key={link.name}>
                        <Link
                          className="nav-link text-white"
                          to={`.${link.link}`}
                          onClick={(e) => {
                            clickHandler(e, link);
                          }}
                        >
                          {link.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <ul className="navbar-nav">
                    {header.email && (
                      <li className="nav-item">
                        <Link
                          className="nav-link text-white"
                          to={`.${header.email.link}`}
                          onClick={(e) => {
                            clickHandler(e, header.email);
                          }}
                        >
                          <i className="fa-solid fa-envelope"></i>{" "}
                          {header.email.name}
                        </Link>
                      </li>
                    )}

                    {header.phone && (
                      <li className="nav-item">
                        <Link
                          className="nav-link text-white"
                          to={`.${header.phone.link}`}
                          onClick={(e) => {
                            clickHandler(e, header.phone);
                          }}
                        >
                          <i className="fa-solid fa-phone"></i>{" "}
                          {header.phone.name}
                        </Link>
                      </li>
                    )}

                    {header.buttons &&
                      header.buttons.map((button) => (
                        <li className="nav-item" key={button.name}>
                          <Link
                            className="nav-link text-white"
                            to={`.${button.link}`}
                            onClick={(e) => {
                              clickHandler(e, button);
                            }}
                          >
                            <i className="fa-solid fa-right-to-bracket"></i>{" "}
                            {button.name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>
      ) : (
        <div className="text-center"></div>
      )}
    </>
  );
};

export default Topmenu;
