import React, { useState, useEffect, useCallback } from "react";
import YAML from "js-yaml";
import { Helmet } from "react-helmet";

let site_config_file;

const Details3 = ({ site_config_url, Theme, Code }) => {
  const [contentDetails, setContentDetails] = useState(null);

  const fetchData = useCallback(async () => {
    if (Code) {
      let site_config = await fetch(site_config_file);
      if (!site_config.ok) {
        return;
      }
      site_config = await site_config.text();
      site_config = YAML.load(site_config).site_config;
      let component = site_config.components.find((item) => item.code === Code);

      if (component && !component.auth) {
        if (component.type === "json") {
          let res = await fetch(component.content_details_url);
          let json = await res.json();

          setContentDetails(json);
        }
        if (component.type === "yml" || component.type === "yaml") {
          let res = await fetch(component.content_details_url);
          let data = await res.text();
          let json = YAML.load(data);
          setContentDetails(json);
        }
      }
      if (component && component.auth) {
      }
    }
  }, [Code]);
  useEffect(() => {
    if (site_config_url) {
      site_config_file = site_config_url;
      fetchData();
    }
  }, [fetchData, site_config_url]);

  return (
    <>
      {contentDetails ? (
        <>
          <Helmet>
            {contentDetails.name && <title>{contentDetails.name}</title>}
            {contentDetails.description && (
              <meta name="description" content={contentDetails.description} />
            )}
            {contentDetails.keyword && (
              <meta name="keywords" content={contentDetails.keyword} />
            )}
          </Helmet>

          <h2 className="fs-3 fw-normal my-3 ">{contentDetails.name}</h2>
          <div className="summary-details">
            <div className="sd-descriptions fw-normal text-secondary fs-6">
              <p
                dangerouslySetInnerHTML={{
                  __html: contentDetails.description,
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: contentDetails.description_1,
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: contentDetails.description_2,
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: contentDetails.description_3,
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="text-center">Loading...</div>
      )}
    </>
  );
};

export default Details3;
