import React, { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import "./schedule.scss";
import YAML from "js-yaml";
let site_config_file;

const Schedule = ({ site_config_url, Theme, Code, source_url }) => {
  const [scheduleData, setScheduleData] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [theme, setTheme] = useState(null);

  const fetchData = useCallback(async () => {
    if (Code) {
      let site_config = await fetch(site_config_file);
      if (!site_config.ok) {
        return;
      }
      site_config = await site_config.text();
      site_config = YAML.load(site_config).site_config;
      let component = site_config.components.find((item) => item.code === Code);

      if (!component) {
        return;
      }

      if (component && !component.auth) {
        if (component.type === "json") {
          let res = await fetch(component.schedule_url);
          let json = await res.json();
          setScheduleData(json);
        }
        if (component.type === "yml" || component.type === "yaml") {
          let res = await fetch(component.schedule_url);
          let data = await res.text();
          let json = YAML.load(data);
          setScheduleData(json);
        }
      }
      if (component && component.auth) {
      }
    }
  }, [Code]);
  const fetchApiData = useCallback(async () => {
    let res = await fetch(source_url);
    let json = await res.json();
    if (Array.isArray(json)) setScheduleData({ content: json });
    else {
      setScheduleData(json);
    }
  }, [source_url]);
  useEffect(() => {
    if (Theme) {
      setTheme(Theme);
    }
    if (site_config_url) {
      site_config_file = site_config_url;
      fetchData();
    }

    if (source_url) {
      fetchApiData();
    }
  }, [fetchData, Theme, site_config_url, fetchApiData, source_url]);

  return (
    <>
      {scheduleData ? (
        <div className="schedule-wrapper p-2">
          {scheduleData.content.map((item) => (
            <div className="sch-row my-3 shadow-sm rounded">
              <div className="sch-title" style={{ backgroundColor: theme }}>
                <span className="text-uppercase">{item.event_title}</span>
                {item.event_code && (
                  <span className="ms-2">&#40; {item.event_code} &#41;</span>
                )}
              </div>
              <div className="sch-body">
                <div className="row fw-bolder ">
                  <div className="col-3 fw-lighter text-secondary ">
                    {item.course_short_description}
                  </div>
                  <div className="col-1 text-center  fw-lighter text-secondary ">
                    {item.start_date} - {item.end_date}
                  </div>
                  <div className="col-2 text-center  fw-lighter text-secondary ">
                    {item.day_names} <br />
                    {item.in_time}-{item.out_time}
                  </div>
                  {item.review_description && (
                    <div
                      className="col-3 text-center  fw-lighter text-secondary "
                      dangerouslySetInnerHTML={{
                        __html: item.review_description.replace("\n", "<br />"),
                      }}
                    />
                  )}
                  <div className="col-1 text-center  fw-lighter text-secondary ">
                    <NavLink
                      to="/schedule"
                      data-bs-toggle="modal"
                      data-bs-target="#tutorModal"
                      onClick={() => {
                        setSelectedSchedule(item);
                      }}
                    >
                      {item.coach_name}
                    </NavLink>
                  </div>
                  <div className="col-1">{item.schedule_capacity}</div>

                  <div className="col-1 text-center  fw-lighter text-secondary ">
                    {item.price && <span>{item.price}</span>}
                    <button
                      style={{
                        padding: "5px 10px",
                        borderRadius: "5px",
                        outline: "none",
                        border: "none",
                        color: "white",
                        backgroundColor: theme,
                      }}
                      onClick={() => {
                        window.open(
                          "https://student.talentacad.com/auth/register"
                        );
                      }}
                    >
                      Enroll
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center">Loading...</div>
      )}

      {/* modal window */}

      {selectedSchedule && (
        <div
          className="modal fade"
          id="tutorModal"
          aria-labelledby="tutorModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="tutorModalLabel">
                  {selectedSchedule.coach_name}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-4">
                    <img
                      alt={selectedSchedule.coach_name}
                      src={selectedSchedule.coach_details}
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-8">
                    <p>{selectedSchedule.course_description}</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Schedule;
