import React from 'react'
const Summer2023 = () => {

    return (
        <div className="p-3">
            <div className='d-flex flex-row justify-content-center container-fluid '>
                <img width="70px" height="70px" className="img img-fluid me-3" src="https://s3.amazonaws.com/cdn.virtusasystems.com/subscriber/elearning/tacad/logo.png" alt="" />
                <h3 className='fs-4 summer-pdf text-center '>Talent Academy Summer Course Offerings <br />
                    <span className='fs-5'>
                        <a href="https://talentacad.com" target="_blank" rel="noreferrer">talentacad.com</a>
                        &nbsp;
                        Whatsapp: 7035319669
                    </span>
                </h3 >

            </div>

            <div className='text-secondary p-3 mt-2' style={{ fontSize: "13px" }}>
                <p className='m-0 mb-1 fw-semibold text-black fs-6' ><a href="https://student.talentacad.com/auth/register" target="_blank" rel="noreferrer">Registration</a> is mandatory for all courses enrollment and does not require any payment at this point of time and make sure you specify your interest during registration.</p>
                <br />
                <p className='m-0 mb-1'>Visit <a href="https://talentacad.com" target="_blank" rel="noreferrer">talentacad.com</a>
                    for more information and secure your place today! <a href="https://student.talentacad.com/auth/register" target="_blank" rel="noreferrer">https://student.talentacad.com/auth/register</a>
                    <span className='fw-semibold text-black'> (Coupon Code - SUMMER2023)</span></p>
                <p className='m-0'>Check out Google reviews for our teachers – Search “Talent Academy reviews”</p>
                <a style={{ wordBreak: "break-word" }} href="https://www.google.com/search?q=talentacad.com+brambelton&rlz=1C5MACD_enIN1046IN1047&oq=talentacad.com+brambelton&aqs=chrome..69i57j33i10i160l3.8769j0j9&sourceid=chrome&ie=UTF-8" target="_blank" rel="noreferrer">https://www.google.com/search?q=talentacad.com+brambelton&rlz=1C5MACD_enIN1046IN1047&oq=talentacad.com+brambelton&aqs=chrome..69i57j33i10i160l3.8769j0j9&sourceid=chrome&ie=UTF-8</a>
                <p className='my-1 m-0'>
                    <span className='fw-semibold text-black'>Explore Opportunities:</span> Stay tuned for summer and volunteer opportunities on our website to enrich your skills and contribute meaningfully.<br />
                    <a href="https://talentacad.com/explore-opportunities" target="_blank" rel="noreferrer">https://talentacad.com/explore-opportunities</a>

                </p>
                <p className=' m-0 mb-1 fw-semibold text-black'>Scheduled Time: To be determined based on students' availability or current batches
                    Discount Coupon: $50 discount for all who register through this flyer!
                </p>
                <p className='m-0'> <span className='fw-semibold text-black'>Price Range:</span> $100 to $130 per month with small-size students (less than 4-5 students per session):
                    Enrollment Confirmation is only based upon receipt of payment.
                </p>
            </div >


            {/* elementry school  */}
            <div className='text-secondary p-3 ' style={{ fontSize: "13px" }}>
                <h3 className='m-0  mb-3 fs-5 p-0'>Elementary School -</h3>
                <div className='my-1'>

                    <a href="https://talentacad.com/cl/maths-grp" className='fs-5' target="_blank" rel="noreferrer">Primary Math/</a>
                    <a href="https://talentacad.com/courses/elem-problem-solving-skills-level3" className='fs-5' target="_blank" rel="noreferrer">Elementary Problem-Solving Skills ($300 for 3 Months)</a>
                    <p>
                        Build a strong foundation in Math! <br />
                        <span className='text-black fw-semibold'>Syllabus:</span> Explore Addition and Subtraction, Multiplication and Division, Fractions, Geometry Basics, Data sequence, Hand Shakes, Greatest value not attained, Number of Divisors, Counting Paths - Walk.<br />
                        <span className='text-black fw-semibold'>Schedule: June 2023-August 2023</span> <br />
                        <span className='text-black fw-semibold'>Note: Please specify "Primary Math/Elementary PSS" in the interest section during registration.</span> <br />

                    </p>
                </div>
                <div className='my-1'>
                    <a href="https://talentacad.com/courses/taacslele" className='fs-5' target="_blank" rel="noreferrer">ACSL Elementary Practice ($100 for 1 Month)</a>

                    <p>
                        <span className='text-black fw-semibold'>Syllabus:</span> Explore elementary Computer Number Systems, Elementary Prefix/Infix/Postfix Notation, Elementary Boolean Algebra, Elementary Graph Theory<br />
                        <span className='text-black fw-semibold'>Schedule: July 2023-July 2023</span> <br />
                        <span className='text-black fw-semibold'>Note: Please specify "ACSL Ele" in the interest section during registration.</span> <br />
                    </p>
                </div>
                <div className='my-1'>
                    <h4 className='fs-6 m-0 text-black'>Elementary Math Olympiad Contest ($200 for 2 Months)</h4>
                    <p>
                        This course is designed for high-achieving elementary school students who want to develop an early interest in <span className='fw-semibold text-black'>problem-solving skills.</span>
                        <br />
                        <span className='text-black fw-semibold'>Syllabus:</span> Explore Hand Shakes, Greatest value not attained, Number of Divisors, Counting Paths - Walk<br />
                        <span className='text-black fw-semibold'>Schedule: July 2023-August 2023</span> <br />
                        <span className='text-black fw-semibold'>Note: Please specify "Math Oly" in the interest section during registration.</span> <br />
                    </p>
                </div>
            </div >

            {/* middle school  */}
            <div className='text-secondary p-3 ' style={{ fontSize: "13px" }}>
                <h3 className='m-0  mb-3 fs-5 p-0'>Middle School -</h3>
                <div className='my-1'>

                    <a href="https://talentacad.com/courses/algebra-vdoe1" className='fs-5' target="_blank" rel="noreferrer">Foundations of Algebra / Grade 6/7 Algebra ($300 for 3 Months)</a>


                    <p>
                        <span className='text-black fw-semibold'>Syllabus:</span> Explore algebra topics absolute value, factoring trinomials, solving equations and inequalities, functions and roots, slope, graphing lines, and transformations. It provides a solid foundation in algebraic concepts and essential skills for further mathematical studies.<br />
                        <span className='text-black fw-semibold'>Schedule: July 2023-September 2023</span> <br />
                        <span className='text-black fw-semibold'>Note: Please specify "FOA" in the interest section during registration.</span> <br />
                    </p>
                </div>
                <div className='my-1'>

                    <a href="https://talentacad.com/courses/vdoe-geometry" className='fs-5' target="_blank" rel="noreferrer">VDOE Geometry/</a>
                    <a href="https://talentacad.com/courses/geometry-level-2" className='fs-5' target="_blank" rel="noreferrer">Geometry Level 2 ($300 for 3 Months)</a>

                    <p>

                        <span className='text-black fw-semibold'>Syllabus:</span> Explore Geometry Logic and Reasoning, Congruent and Similar Triangles, Segment Lengths, Arc Length and Area of a Sector, Practical Problems Involving Circles, Equation of a Circle<br />
                        <span className='text-black fw-semibold'>Schedule: July 2023-September  2023</span> <br />
                        <span className='text-black fw-semibold'>Note: Please specify "VDOE/Geo Level 2" in the interest section during registration.</span> <br />
                    </p>
                </div>
                <div className='my-1'>
                    <a href="https://talentacad.com/courses/itcp-java" className='fs-5' target="_blank" rel="noreferrer">Summer Introduction to JAVA Course and Coding Labs ($400 for 4 Months)</a>


                    <p>
                        <span className='text-black fw-semibold'>Syllabus:</span>  Explore Java Spring Boot Setup, Primitive Data, Input and Output, Loops and the while statement, Variables and the Assignment Statement, Expressions, and Arithmetic Operators<br />
                        <span className='text-black fw-semibold'>Schedule: June 2023-September 2023</span> <br />
                        <span className='text-black fw-semibold'>Note: Please specify "JAVA" in the interest section during registration.</span> <br />
                    </p>
                </div>
            </div>

            <div className='text-secondary p-3 ' style={{ fontSize: "13px" }}>

                <h3 className='m-0  mb-3 fs-5 p-0'>Problem-Solving Skills and Contest Practice/Review  -</h3>
                <div className='my-1'>

                    <h4 className='fs-6 m-0 text-black'>Problem-Solving Skills by Utkarsh Goyal and Adya Garg ($100 per week, 75 minutes Mon-Wed-Fri 3 times a week)</h4>
                    <p>
                        Enhance your abilities! Only 2 Spot Left on Tue/Fr.<br />
                        <span className='text-black fw-semibold'>Syllabus:</span> Explore Probability/Rolling Dice, Sequences of Numbers, Events, Probability
                        For high-achieving students in school mathematics looking to build a background in competition maths, including AMC 10 and AMC 12.
                        <br />
                        <span className='text-black fw-semibold'>Schedule: July 2023-August 2023</span> <br />
                        <span className='text-black fw-semibold'>Location: Brambleton Library </span> <br />
                        <span className='text-black fw-semibold'>Note: Please specify "PSS Contest" in the interest section during registration.</span> <br />
                    </p>
                </div>
                <div className='my-1'>
                    <h4 className='fs-6 m-0 text-black'>Contest Practice by Utkarsh Goyal and Adya Garg ($100 per week) ($100 per week, 75 minutes Tue/Thu 2 times a week)</h4>
                    <p>
                        Preparing to qualify for AIME, AMC 10, USAJMO, and USAMO (USA Mathematical Olympiads).<br />
                        <span className='text-black fw-semibold'>Syllabus:</span> AMC 8, AMC 10, Math count Exam Practice and ReviewSector, Practical Problems Involving Circles, Equation of a Circle<br />
                        <span className='text-black fw-semibold'>Schedule: July 2023-August 2023</span> <br />
                        <span className='text-black fw-semibold'>Location: Brambleton Library </span> <br />
                        <span className='text-black fw-semibold'>Note: Please specify "PSS Contest" in the interest section during registration.</span> <br />
                    </p>
                </div>
                <div className='my-1'>
                    <a href="https://talentacad.com/courses/sat-math" className='fs-5' target="_blank" rel="noreferrer">SAT Math Utkarsh Goyal and Adya Garg ($150 per week, 75 minutes, 5 days)</a>

                    <p>
                        <span className='text-black fw-semibold'>Syllabus:</span> SAT Math Exam Practice and Review<br />
                        <span className='text-black fw-semibold'>Schedule: July 2023-August 2023</span> <br />
                        <span className='text-black fw-semibold'>Location: Brambleton Library </span><br />
                        <span className='text-black fw-semibold'>Note: Please specify "SAT Contest" in the interest section during registration.</span> <br />
                    </p>
                </div>
            </div>


            {/* high school  */}
            <div className='text-secondary p-3 ' style={{ fontSize: "13px" }}>
                <h3 className='m-0  mb-3 fs-5 p-0'>High School   -</h3>
                <div className='my-1'>
                    <h4 className='fs-6 m-0 text-black'>High School Mathematics ($250 for 2 Months)</h4>
                    <p>
                        <span className='text-black fw-semibold'>Syllabus:</span> A year-long course designed to guide your student through the rigor of the BC Calculus curriculum, including integral techniques and problem-solving with units, disks, and washers. Adaptable to students’ weaknesses and strengths.
                        <br />
                        <span className='text-black fw-semibold'>Schedule: July’2023-August’2023</span><br />
                        <span className='text-black fw-semibold'>Note: Please specify "HS Math" in the interest section during registration.</span> <br />
                    </p>
                </div>
                <div className='my-1'>
                    <a href="https://talentacad.com/courses/apcs" className='fs-5' target="_blank" rel="noreferrer">Computer Science ($250 for 2 Months)</a>

                    <p>
                        Summer APCS and Coding Labs<br />
                        <span className='text-black fw-semibold'>Syllabus:</span>  Explore Explore Inputs and outputs, Arrays, aed lists, Stacks, Queues, Streams and buffers, Recursion, OOP, Java <br />
                        <span className='text-black fw-semibold'>Schedule: July’2023-August’2023</span> <br />
                        <span className='text-black fw-semibold'>Note: Please specify "CS" in the interest section during registration.</span> <br />
                    </p>
                </div>
                <div className='my-1'>
                    <a href="https://talentacad.com/courses/ta-arml" className='fs-5' target="_blank" rel="noreferrer">ARML Loudoun Team ($200 yearly) FCPS students are not eligible</a>
                    <p>
                        Talent Academy runs an official  ARML tournament for Loudoun High School students with a valid LCPS student id. Fairfax and Thomas Jefferson students are not eligible to register in the Loudoun ARML tournament. We will be practicing at Brambelton Library from Nov'2023 to May'2024. The tournament is also open to middle schoolers who have scored more than 90+ in AMC 10.<br />
                        <span className='text-black fw-semibold'>Schedule: November’2023 - May’2024.</span> <br />
                        <span className='text-black fw-semibold'>Note: Please specify "ARML" in the interest section during registration.</span> <br />

                    </p>
                </div>
                <div className='my-1'>
                    <h4 className='fs-6 m-0 text-black'>Self-Paced ($100-$250 yearly)</h4>
                    <p>
                        <a href="https://talentacad.com/courses/moems-exams" target="_blank" rel="noreferrer">Moems enrollment</a>
                        <span className='text-black fw-semibold'> as a self-paced with a team: $100 year Grade 3 - Grade 5</span><br />
                        <span className='text-black fw-semibold'>Syllabus:</span> Talent Academy offers MOEMS. <br />
                        <span className='text-black fw-semibold'>Schedule: Oct’2023-May’2024</span> <br />
                        <span className='text-black fw-semibold'>Note: Please specify "MOEMS" in the interest section during registration.</span> <br />


                    </p>
                    <p>
                        <a href="https://talentacad.com/courses/acslidcc" target="_blank" rel="noreferrer">ACSL Intermediate Enrollment</a>
                        <span className='text-black fw-semibold'> as a self-paced with a team: $150 year Grade 8-Grade 10</span><br />
                        <span className='text-black fw-semibold'>Syllabus:</span> Explore Strings, Arrays, Compressed Arrays, Lex strings, Triangle numbers, Triangles, Poker, Duodecim Scripta, Strings and Things, Sudoku, Trees<br />
                        <span className='text-black fw-semibold'>Schedule: Oct’2023-May’2024</span> <br />
                        <span className='text-black fw-semibold'>Note: Please specify "ACSL Intr" in the interest section during registration.</span> <br />

                    </p>
                    <p>
                        <a href="https://talentacad.com/courses/tasdcc" target="_blank" rel="noreferrer">ACSL Senior Enrollment</a>
                        <span className='text-black fw-semibold'> as a self-paced with a team: $150 year Grade 10-Grade 12</span><br />
                        <span className='text-black fw-semibold'>Syllabus:</span> Explore Navigating ACSL land, Hex Grid Walks, Components, Booleon, 3x2, Detect, Grid, Draft Picks, Traffic, Cells, Lorenz Cipher Machine Encode<br />
                        <span className='text-black fw-semibold'>Schedule: Oct’2023-May’2024</span> <br />
                        <span className='text-black fw-semibold'>Note: Please specify "ACSL Sr" in the interest section during registration.</span> <br />
                    </p>
                </div>


            </div >

            {/* instructors  */}
            <div className='text-secondary p-3 ' style={{ fontSize: "13px" }}>
                <h3 className='m-0  mb-3 fs-5 p-0'>Instructors  -</h3>
                <a href="https://talentacad.com/instructors" target="_blank" rel="noreferrer">https://talentacad.com/instructors</a>

                <div className='mt-2'>
                    <h4 className='fs-6 m-0 text-black'>Vandana Goyal</h4>
                    <p>
                        Vandana Goyal is a dedicated and passionate educator with over two decades of teaching experience. Since 1998, she has been actively engaged in teaching primary math students and computer students. Vandana possesses a unique ability to connect with younger kids, fostering a nurturing and supportive learning environment. Her passion for teaching is evident in her innovative approaches and commitment to ensuring that every student receives a quality education. Vandana's extensive experience and genuine enthusiasm make her a beloved teacher, leaving a lasting impact on the young minds she guides. Parents can further attest to Vandana's exceptional teaching abilities through the positive reviews available on Google.
                    </p>
                </div>
                <div className='mt-2'>
                    <h4 className='fs-6 m-0 text-black'>Utkarsh Goyal</h4>
                    <p>
                        Utkarsh Goyal has established himself as a prominent figure in mathematics education. As a two-time USA(J)MO qualifier, Utkarsh has showcased exceptional problem-solving skills and mathematical prowess on a national level. Additionally, his impressive feat of qualifying for the American Invitational Mathematics Examination (AIME) four times highlights consistent excellence in the subject. Utkarsh's achievements testify to his deep understanding of advanced mathematical concepts and his ability to inspire and guide students toward success in mathematics.
                    </p>
                </div>
                <div className='mt-2'>
                    <h4 className='fs-6 m-0 text-black'>Adya Garg</h4>
                    <p>
                        Adya Garg, along with a team of talented individuals, including Jessica Chen, Anvi Borkar, Sameeha Shaik, Aaditya Kasture, Kaitlin Puffenberger, and Avni Garg, has authored a remarkable Geometry book. This comprehensive resource, meticulously written and edited by the team, offers in-depth explanations of geometric concepts for students and educators. Adya's leadership, combined with the expertise and contributions of the co-authors/editors, has resulted in a valuable book that serves as a guide for mastering Geometry. Their collaborative efforts have produced a resource that empowers learners to understand and excel in the study of Geometry.
                    </p>
                </div>
                <p className='m-0 mb-1 fw-semibold text-black'>Reserve your spot now and secure your spot in the course! Registration does not require payment at this time. Simply provide your course preference during the registration process in the Note section, and our support team will reach out to you for payment details at a later stage.</p>
            </div >

        </div >
    )
}

export default Summer2023
