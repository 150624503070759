import React, { useState } from 'react'
import json from "../schedule_data"
import site_config_file from "../site_config.yml";
import Schedule from "../componentui/Schedule/Schedule";


const ClassSchedule = ({ Theme }) => {
    const [theme, setTheme] = useState("#4B606B");
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [groups, setGroups] = useState(null);

    useState(() => {
        setTheme(Theme)
        setGroups(json);
    }, [])



    return (


        <div className='p-2'>

            {


                groups && groups.length && groups.map((group, index) => (

                    <div key={index} >
                        < div className="p-3 rounded row shadow  my-2 align-items-center" style={{ backgroundColor: theme }}>
                            <div className='col-11'>
                                <h3 className='text-white fs-5 my-1'>{group.name}</h3>
                                <p className='text-white fw-semibold' style={{ fontSize: "13px" }}>{group.description}</p>
                            </div>

                            {

                                <div className='col-1 fs-5 text-white '>

                                    {
                                        selectedGroup && selectedGroup.code === group.code ?
                                            < i className="fa-solid fa-minus" style={{ cursor: "pointer" }} onClick={() => { setSelectedGroup(null) }}></i>
                                            :
                                            <i className="fa-solid  fa-plus" style={{ cursor: "pointer" }} onClick={() => { setSelectedGroup(group) }}></i>
                                    }
                                </div>
                            }


                        </div >

                        <div>

                            {
                                selectedGroup && selectedGroup.code === group.code &&
                                <Schedule
                                    // source_url={selectedGroup.url}
                                    site_config_url={`${window.location.protocol}//${window.location.host}${site_config_file}`}
                                    Theme="#8F5875"
                                    Code={'TAACSLJr'}

                                />
                            }
                        </div>


                    </div>

                ))
            }
        </div>


    )
}

export default ClassSchedule
