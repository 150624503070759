import React, { useEffect, useState } from 'react'
import json from './data'

const Register = () => {
    const [data, setData] = useState(null);
    useEffect(() => {
        setData(json);
    }, [])
    return (
        <div className="container justify-content-center d-flex row">
            {
                data &&
                <div className='border p-4 rounded justify-content-center  col-9' style={{ color: data.font_color, backgroundColor: data.bg_color }} >

                    <div >
                        <h3 className='text-center '>{data.title}</h3>
                        <div className="row">
                            {
                                data.form_fields && data.form_fields.length > 0 &&
                                data.form_fields.map((item) => (
                                    < div class="mb-3 col-md-6">
                                        {
                                            item.type !== "select" ?
                                                <>
                                                    < label class="form-label fw-semibold ">{item.label}</label>
                                                    {
                                                        item.required ?
                                                            <input type={item.type} required placeholder={item.placeholder} class="form-control form-control-sm" /> :
                                                            <input type={item.type} placeholder={item.placeholder} class="form-control form-control-sm" />
                                                    }
                                                </>
                                                :
                                                <>
                                                    < label class="form-label fw-semibold ">{item.label}</label>
                                                    <select class="form-select" >

                                                        {
                                                            item.options && item.options.map((option) => (
                                                                <option value={option.value}>{option.name}</option>
                                                            ))
                                                        }
                                                    </select>


                                                </>

                                        }
                                    </div>
                                ))
                            }

                        </div>
                        <div className="row justify-content-center">
                            {
                                data.submit_btn &&
                                <button className='btn col-3 mt-2' style={{ color: data.submit_btn.font_color, backgroundColor: data.submit_btn.bg_color }} >{data.submit_btn.text}</button>
                            }
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}

export default Register


