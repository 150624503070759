import React, { useState, useEffect, useCallback } from "react";
import "./dashboard.scss";
import YAML from "js-yaml";

let site_config_file;

const Dashboard = ({ site_config_url, Theme, Code }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [theme, setTheme] = useState(null);

  const handleClick = (name) => {
    setSelectedItem(() => dashboardData.find((item) => item.name === name));
  };

  const fetchData = useCallback(async () => {
    if (Code) {
      let site_config = await fetch(site_config_file);
      if (!site_config.ok) {
        return;
      }
      site_config = await site_config.text();
      site_config = YAML.load(site_config).site_config;
      let component = site_config.components.find((item) => item.code === Code);

      if (!component) {
        alert("data not found");
        return;
      }

      if (component && !component.auth) {
        if (component.type === "json") {
          let res = await fetch(component.dashboard_url);
          let json = await res.json();
          setDashboardData(json);
        }
        if (component.type === "yml" || component.type === "yaml") {
          let res = await fetch(component.dashboard_url);
          let data = await res.text();
          let json = YAML.load(data);
          setDashboardData(json);
        }
      }
      if (component && component.auth) {
      }
    }
  }, [Code]);
  useEffect(() => {
    if (Theme) {
      setTheme(Theme);
    }
    if (site_config_url) {
      site_config_file = site_config_url;
      fetchData();
    } else {
    }
  }, [fetchData, Theme, site_config_url]);

  useEffect(() => {
    if (dashboardData) setSelectedItem(dashboardData[0]);
  }, [dashboardData]);

  return (
    <>
      {dashboardData ? (
        <div className="dashboard-wrapper">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            {dashboardData.map((item, index) => (
              <li className="nav-item" role="presentation" key={index}>
                <button
                  className="nav-link active text-white "
                  style={{ backgroundColor: theme }}
                  key={item.name}
                  onClick={() => {
                    handleClick(item.name);
                  }}
                >
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="row">
              {selectedItem &&
                selectedItem.children.map((item) => (
                  <div className="col-md-3 py-2" key={item.name}>
                    <div>
                      <h5
                        className="fw-semibold"
                        style={{
                          display: "inline-block",
                          transition: "0.5s all",
                          fontSize: "16px",
                        }}
                      >
                        {item.name}
                      </h5>

                      {item.children.map((item, index) => (
                        // <li style={{ fontSize: "15px" }} key={item.name}>
                        //   {item.name}
                        // </li>
                        <p
                          key={index}
                          style={{ fontSize: "15px" }}
                          className="m-0  "
                        >
                          <span className="fw-semibold me-2">{item.index}</span>
                          {item.name}
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <>Data not found</>
      )}
    </>
  );
};

export default Dashboard;
