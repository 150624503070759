import React from 'react'

const Explorer = () => {
    return (
        <iframe
            src="https://treeexplorerreactui.virtusasystems.com/opportunities-explorer"
            title='explore opportunity'
            loading="lazy"
            seamless
            style={{ position: 'relative', width: '100%', height: '600px', transform: "translateX(-20px)" }}
        ></iframe>
    )
}

export default Explorer
