import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import YAML from "js-yaml";
import "./acsl-theory.scss";

let site_config_file;

const Content = ({ site_config_url, Theme, Code }) => {
  const [content, setContent] = useState(null);
  const [theme, setTheme] = useState("#4B606B");
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    if (Code) {
      let site_config = await fetch(site_config_file);
      if (!site_config.ok) {
        return;
      }
      site_config = await site_config.text();
      site_config = YAML.load(site_config).site_config;
      let component = site_config.components.find((item) => item.code === Code);

      if (!component) {
        return;
      }

      if (component && !component.auth) {
        if (component.type === "json") {
          let res = await fetch(component.source_url);
          let json = await res.json();
          setContent(json);
        }
        if (component.type === "yml" || component.type === "yaml") {
          let res = await fetch(component.source_url);
          let data = await res.text();
          let json = YAML.load(data);
          console.log(json);

          setContent(json);
        }
      }
      if (component && component.auth) {
      }
    }
  }, [Code]);
  useEffect(() => {
    if (Theme) {
      setTheme(Theme);
    }
    if (site_config_url) {
      site_config_file = site_config_url;
      fetchData();
    } else {
      alert("data not found");
    }
  }, [fetchData, Theme, site_config_url]);
  const onClickHandler = (e, a) => {
    e.preventDefault();
    if (a.redirect) {
      window.open(a.link, "_blank");
      return;
    }
    navigate(`/${a.link}`);
  };

  return (
    <div className="container-fluid">
      {content ? (
        <>
          <Helmet>
            {content.name && <title>{content.name}</title>}
            {content.description && (
              <meta name="description" content={content.description} />
            )}
            {content.keyword && (
              <meta name="keywords" content={content.keyword} />
            )}
          </Helmet>

          <h1 className="text-dark fw-normal fs-3 m-2 ">{content.name}</h1>
          <div className="acsl-theory">
            <div className="row">
              {content &&
                content.items.map((course, index) => (
                  <>
                    {course.course_status &&
                      course.course_status === "live" && (
                        <div className="col-md-6" key={course.course_id}>
                          <div className="ta-card shadow-sm rounded text-secondary">
                            <div
                              className="ta-card-header"
                              style={{ backgroundColor: theme }}
                            >
                              <h2>{course.name} </h2>
                            </div>
                            <div className="ta-card-body">
                              {course.short_description}
                            </div>
                            <div className="ta-card-footer">
                              <Link
                                to="/enroll"
                                onClick={(e) => {
                                  onClickHandler(e, course.links.enroll);
                                }}
                              >
                                <i className="fa-solid fa-pencil"></i>
                                Enroll
                              </Link>
                              <Link
                                to="/summary"
                                onClick={(e) => {
                                  onClickHandler(
                                    e,
                                    course.links.course_details
                                  );
                                }}
                              >
                                <i className="fa-solid fa-book"></i>
                                Course Details
                              </Link>
                              <Link
                                to="/schedule"
                                onClick={(e) => {
                                  onClickHandler(e, course.links.schedule);
                                }}
                              >
                                <i className="fa-solid fa-calendar-days"></i>
                                Schedule
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                ))}
            </div>
          </div>
        </>
      ) : (
        <div className="text-center">Loading...</div>
      )}
    </div>
  );
};

export default Content;
