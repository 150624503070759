import React, { useEffect, useState } from "react";
import YAML from "js-yaml";
import { Routes, Route, useParams } from "react-router-dom";
import Content from "./componentui/Content/Content";
import Results from "./componentui/Results/Results";
import Calendar from "./component/Calendar";
import { Helmet } from "react-helmet";
import Aboutus from "./component/Aboutus";
import Explorer from "./component/Explorer";
import Home from "./component/Home";
import DetailsPage from "./component/DetailsPage";
import Profile from "./componentui/Profile/Profile";
import Schedule from "./componentui/Schedule/Schedule";
import site_config_file from "./site_config.yml";
import Slider from "./componentui/Slider/Slider";
import ClassSchedule from "./component/ClassSchedule";
import Register from "./componentui/Register/Register";
import Summer2023 from "./component/Summer2023";
import MainComponent from "./MainComponent";
import Instructor from "./componentui/Instructor/Instructor";

const ScheduleWrapper = () => {
  const params = useParams();
  return (
    <Schedule
      site_config_url={`${window.location.protocol}//${window.location.host}${site_config_file}`}
      Theme="#8F5875"
      Code={params.course}
    />
  );
};

const ContentWrapper = () => {
  const params = useParams();
  return (
    <Content
      site_config_url={`${window.location.protocol}//${window.location.host}${site_config_file}`}
      Theme="#8F5875"
      Code={params.courseGroup}
    />
  );
};

const App = () => {
  const [title, setTitle] = useState(null);

  const fetchSiteconfig = async () => {
    let site_config = await fetch(site_config_file);
    if (!site_config.ok) {
      return;
    }
    site_config = await site_config.text();
    site_config = YAML.load(site_config).site_config;
    if (site_config.title) {
      setTitle(site_config.title);
    }
  };
  useEffect(() => {
    fetchSiteconfig();
  }, []);
  return (
    <>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}

      <Routes>
        <Route path="/" element={<MainComponent />}>
          <Route path="/" element={<Home />} />
          <Route path="/instructors" element={<Instructor />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/new-register-now" element={<Register />} />
          <Route path="/explore-opportunities" element={<Explorer />} />
          <Route
            path="/class-schedules"
            element={<ClassSchedule Theme="#8F5875" />}
          />
          <Route
            // courseGroup
            path="/cl/:courseGroup"
            element={<ContentWrapper />}
          />
          <Route path="/courses/:course" element={<DetailsPage />} />
          <Route path="/cs/schedules/:course" element={<ScheduleWrapper />} />
          <Route path="/ce/:cal" element={<Calendar />} />
          <Route
            path="/results"
            element={
              <Results
                site_config_url={`${window.location.protocol}//${window.location.host}${site_config_file}`}
                Theme="#8F5875"
                Code={"results"}
              />
            }
          />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route
            path="/reviews"
            element={
              <Slider
                site_config_url={`${window.location.protocol}//${window.location.host}${site_config_file}`}
                Theme="#8F5875"
                Code="reviews"
              />
            }
          />
          <Route path="/schedule" element={<Calendar />} />
          <Route path="/:link" element={<div>Under Construction</div>} />
        </Route>
        <Route path="/summer-2023" element={<Summer2023 />} />
      </Routes>
    </>
  );
};

export default App;
