import React, { useEffect, useState } from 'react'
import Slider from '../componentui/Slider/Slider'
import Dashboard from '../componentui/Dashboard/Dashboard'
import ContentDetails from '../componentui/ContentDetails/ContentDetails'
import Calendar from './Calendar'
import YAML from "js-yaml";
import detailspage_config from '../data/yml/detailspage_config.yml'
import site_config_file from "../site_config.yml";
import { useParams } from 'react-router'


const DetailsPage = () => {
    const [component, setComponent] = useState('contentdetails');
    const params = useParams();
    const [tabs, setTabs] = useState(null);
    useEffect(() => {
        let url = window.location.protocol + "//" + window.location.host + detailspage_config
        fetch(url)
            .then(res => res.text())
            .then((data) => {
                let d = YAML.load(data)
                setTabs(d);
            });

    }, [])
    return (
        <>
            {
                tabs && tabs.length > 0 && <>
                    <div>
                        <ul className="nav nav-tabs mt-2">
                            {
                                tabs && tabs.map((item, index) => (
                                    <li className="nav-item" key={index}>
                                        <button className="nav-link rounded mb-2 me-1" style={{ backgroundColor: '#8F5875', color: "#fff" }} onClick={() => { setComponent(item.component) }}>{item.tab}</button>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                </>

            }

            <div>
                <div className="my-2" style={{ minHeight: "500px" }}>
                    {
                        component === 'dashui' &&
                        <Dashboard
                            site_config_url={`${window.location.protocol}//${window.location.host}${site_config_file}`}
                            Theme="#4B606B"
                            Code={params.course}
                        />
                    }
                    {
                        component === 'sliderui' &&
                        <Slider
                            site_config_url={`${window.location.protocol}//${window.location.host}${site_config_file}`}
                            Theme="#4B606B"
                            Code={params.course}
                        />
                    }
                    {
                        component === 'contentdetails' &&
                        <ContentDetails
                            site_config_url={`${window.location.protocol}//${window.location.host}${site_config_file}`}
                            Theme="#4B606B"
                            Code={params.course}

                        />
                    }
                    {
                        component === 'calui' &&
                        <Calendar />
                    }
                </div>
            </div>




        </>
    )
}

export default DetailsPage
