import React from 'react'

const Home = () => {

    return (
        <div className="p-3 rounded ">
            <h3 className='mb-3'>Home</h3>
            <p className='text-black-50'>
                Talent Academy follows an amazing online learning curriculum for students of all age groups. We offer small batch sizes with dedicated experts to enhance the learning experience. We offer numerous worksheets and practice materials for students to practice during their free time. All queries can be discussed and resolved in subsequent review sessions where they get one to one dedicated discussion time. Online sessions are not like any other recorded classes but are live sessions where there is more interaction and conversation but not just a one way lecture that enables students to be attentive and increases class participation
            </p>
            <p className='text-black-50'>
                Get introductions to algebra, geometry, trigonometry, precalculus, calculus, java, AMC 8, AMC 10, and MOEMS contest participation, free events and more. Get help with current math and computer science coursework and competitive exam preparation.
            </p>
            <p className='text-black-50'>
                Select a course to learn more. Students can register to the schedule based on their convenience and more excitingly learn sitting at their home comfort.The only requirement is a working computer with internet access and a comfy place to attend the sessions. All the sessions are approximately one to one and a half hours which is ideal for learning students. Review sessions are arranged at different hours of the day to avoid overloading for the students. Our classroom is your training ground for the impact you can have in your career field.
            </p>
            <p className='text-black-50'>
                Enrolling in live online academic classes is an excellent way to enhance your learning experience. Talent Academy offers a wide variety of classes in different fields that are taught by experienced and qualified instructors. Our platform is user-friendly, and classes can be accessed from anywhere in the world. With interactive learning tools and regular assessments, you can be sure that you are getting the most out of your education. Enroll in our live online academic classes today and experience learning made easy.
            </p>
            <h1 className='d-none'>
                Talentacad: Enroll in Live Online Academic Classes in Ashburn, Talentacad, talently login, Talent,academy, e-learning, TA , Talent Acad, acad,North Virginia, courses
            </h1>
            <p className='d-none'>
                The only requirement is a working computer with internet access and a comfy place to attend the sessions. All the sessions are approximately one to one and a half hours which is ideal for learning students. Review sessions are arranged at different hours of the day to avoid overloading for the students. Our classroom is your training ground for the impact you can have in your career field.   </p>
        </div>
    )
}

export default Home
