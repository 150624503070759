import React from 'react'

const Aboutus = () => {
    document.title = 'About us || Tacad'
    return (
        <>
            <div className="p-3">
                <h3 className='mb-3'>About Us</h3>
                <p className='text-secondary'>
                    Talent Academy follows an amazing online learning curriculum for students of all age groups. We offer small batch sizes with dedicated experts to enhance the learning experience, which is a maximum of 5-6 students in each batch with two dedicated teaching experts. We offer numerous worksheets and practice materials for students to practice during their free time and clarify any doubts during the subsequent session or schedule a review session. All queries can be discussed and resolved in subsequent review sessions where they get one-to-one dedicated discussion time. Online sessions are not like any other recorded classes but are live sessions where there is more interaction and conversation but not just a one-way lecture that enables students to be attentive and increases class participation.
                </p>
                <p className='text-secondary'>
                    Get introductions to algebra, advanced algebra, prealgebra, Primary mathematics for level 3,4,5,6, geometry, trigonometry, precalculus, calculus, NASA Math with science, foundation to computer science, java, python, more than 400 coding lab worksheet access, AP computer science, American Mathematics Contest (AMC) 8, AMC 10, American Invitational Mathematics Examination - AIME, MOEMS contest participation, computer structure, free events, and more. Get help with current math and computer science coursework and competitive exam preparation all at one single platform. We provide elementary problem-solving modules for levels 3, 4, and 5, special problem-solving modules for beginners, problem-solving mini, and advanced. Based on the student's interest and learning zeal students can select the level they want to enroll into. We offer a wide bucket of SOL live sessions and working modules that include, SOL algebra, SOL geometry, and all levels of SOL. For the mathematic genius. we offer Arithematics games practice sessions. With all these courses we have ACSL theory and coding previous year question papers for students to access and start practicing right away. Students can enroll in as many courses as interested and select the available schedules.
                </p>
                <p className='text-secondary'>
                    Select a course based on your keen learning interest and start your learning journey right away. Students can register to the schedule based on their convenience and more excitingly learn sitting at your home comfort. The only requirement is a working computer with internet access and a comfy place to attend the sessions. All the sessions are approximately one to one and a half hours which is ideal for learning students. Review sessions are arranged at different hours of the day to avoid overloading for the students. Our classroom is your training ground for the impact you can have in your career field. Talent Academy offers a hands-on, immersive curriculum that is your directly transferrable foundation of skills and knowledge you can apply from day one in your career. We deliver our coursework online, on your time. our user-friendly platform provides all the interactions of a traditional classroom in a flexible, online environment. We offer synchronous and asynchronous classes, allowing you to complete your coursework within the stipulated time frame.
                </p>
                <h1 className='d-none'>
                Talentacad: Enroll in Live Online Academic Classes in Ashburn, Talentacad, talently login, Talent,academy, e-learning, TA , Talent Acad, acad,North Virginia, courses
                </h1>
                <p className='d-none'>
                    The only requirement is a working computer with internet access and a comfy place to attend the sessions. All the sessions are approximately one to one and a half hours which is ideal for learning students. Review sessions are arranged at different hours of the day to avoid overloading for the students. Our classroom is your training ground for the impact you can have in your career field.   
                 </p>
        
            </div>
        </>
    )
}

export default Aboutus
