import React, { useEffect, useState } from 'react'
import json from './data'
const Profile = () => {
    const [profileData, setProfileData] = useState(null);

    useEffect(() => {
        document.getElementById("profile-btn").click();
        setProfileData(json);
    }, [])
    return (
        <>

            <button type="button" id="profile-btn" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button>
            <div class="modal modal-lg fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog container modal-dialog-centered">
                    <div class="modal-content">
                        {
                            profileData &&
                            <>

                                <div className="modal-header">
                                    <h5 className="modal-title" id="tutorModalLabel">
                                        {profileData.name}
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>

                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img
                                                alt={profileData.name}
                                                src={profileData.img}
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="col-md-8">
                                            <p>{profileData.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </>


                        }
                    </div>
                </div>
            </div>
        </>

    )
}

export default Profile
