import React, { useEffect, useState, useCallback } from "react";
import './results.css';
import YAML from "js-yaml";


let site_config_file;

const Results = ({ site_config_url, Theme, Code }) => {
    const [content, setContent] = useState(null);
    const [subContent, setSubContent] = useState(null);
    const [resultsData, setResultsData] = useState(null);
    const [theme, setTheme] = useState(null);
    const fetchData = useCallback(async () => {
        if (Code) {
            let site_config = await fetch(site_config_file);
            if (!site_config.ok) {
                return;
            }
            site_config = await site_config.text();
            site_config = YAML.load(site_config).site_config;
            let component = site_config.components.find(
                (item) => item.code === Code
            );

            if (!component) {
                alert("data not found");
                return;
            }

            if (component && !component.auth) {
                if (component.type === "json") {
                    let res = await fetch(component.source_url);
                    let json = await res.json();
                    setResultsData(json)
                }
                if (component.type === "yml" || component.type === "yaml") {
                    let res = await fetch(component.source_url);
                    let data = await res.text();
                    let json = YAML.load(data);
                    setResultsData(json)
                }
            }
            if (component && component.auth) {

            }


        }
    }, [Code])
    useEffect(() => {
        if (Theme) {
            setTheme(Theme);
        }
        if (site_config_url) {
            site_config_file = site_config_url;
            fetchData();
        } else {
        }
    }, [fetchData, Theme, site_config_url]);


    useEffect(() => {
        if (resultsData && resultsData.length)
            setContent(resultsData[0].content);
    }, [resultsData])
    useEffect(() => {
        if (content) {

            setSubContent(content[0].content);
        }
    }, [content])
    return (
        <>
            {
                resultsData ?
                    <div >
                        <div className="d-flex">
                            {
                                resultsData && resultsData.map((item) => (
                                    <button className="theme-btn fw-normal" style={{ backgroundColor: theme }} onClick={() => { setContent(item.content) }}>{item.tab}</button>
                                ))
                            }
                        </div>
                        <div className="content border mt-2">
                            <div className="d-flex m-2 ">
                                {
                                    content && content.map((item) => (
                                        <>
                                            <button className="theme-btn fw-normal" style={{ fontSize: '13px', padding: '6px 9px', backgroundColor: theme }} onClick={() => { setSubContent(item.content) }}>{item.tab}</button>

                                        </>

                                    ))
                                }
                            </div>
                            {
                                subContent &&
                                subContent.paragraphs.map((paragraph) => (
                                    <>
                                        <p className="text-secondary fw-lighter m-2 p-2">{paragraph}</p>
                                    </>

                                ))
                            }

                            <div className="container-fluid  p-4">
                                {
                                    subContent && subContent.images.map((image) => (
                                        <>
                                            <img src={image.url} alt={image.alt} style={{ width: image.width, height: image.height }} className="img-fluid m-2" />
                                        </>

                                    ))
                                }
                            </div>
                        </div>
                    </div> :
                    <>
                        data not found
                    </>
            }
        </>
    )
}

export default Results;