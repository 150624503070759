import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./footer.scss";
import YAML from "js-yaml";

const Footer = ({ Url, Type, Theme }) => {
  const [footerData, setFooterData] = useState(null);
  const [theme, setTheme] = useState("#4B606B");
  const navigate = useNavigate();
  const fetchData = useCallback(async () => {
    if (Theme) {
      setTheme(Theme);
    }
    if (Type === "yml") {
      let res = await fetch(Url);
      let data = await res.text();
      let json = YAML.load(data);
      setFooterData(json);
    }
    if (Type === "json") {
      let res = await fetch(Url);
      let json = await res.json();
      setFooterData(json);
    }
  }, [Theme, Type, Url]);

  const clickHandler = (e, a) => {
    e.preventDefault();
    if (a.redirect) {
      window.open(a.link, "_blank");
      return;
    }
    navigate(`.${a.link}`);
  };
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {footerData ? (
        <div
          className="footer container-fluid "
          style={{ backgroundColor: theme }}
        >
          <div className="footer-nav">
            {footerData.primaryLinks.map((item, index) => (
              <div className="f-item" key={index}>
                <ul>
                  <li className="nav-title">{item.name}</li>
                  {item.links.map((link, index) => (
                    <li key={index}>
                      <Link
                        to={`.${link.link}`}
                        onClick={(e) => {
                          clickHandler(e, link);
                        }}
                      >
                        {link.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            <div className="f-item">
              <ul>
                <li className="nav-title">{footerData.contactUs.name}</li>
                <li>
                  <Link
                    to={`.${footerData.contactUs.email.link}`}
                    onClick={(e) => {
                      clickHandler(e, footerData.contactUs.email);
                    }}
                  >
                    <i className="fa-solid fa-envelope"></i>
                    {footerData.contactUs.email.text}
                  </Link>
                </li>
                <li>
                  <Link
                    to={`.${footerData.contactUs.phone.link}`}
                    onClick={(e) => {
                      clickHandler(e, footerData.contactUs.phone);
                    }}
                  >
                    <i className="fa-solid fa-phone"></i>{" "}
                    {footerData.contactUs.phone.text}
                  </Link>
                </li>
                <li className="social-media ">
                  <Link
                    to={`.${footerData.contactUs.facebook.link}`}
                    onClick={(e) => {
                      clickHandler(e, footerData.contactUs.facebook);
                    }}
                  >
                    <i className="fa-brands fa-facebook-square fs-5"></i>
                  </Link>
                  <Link
                    to={footerData.contactUs.twitter.link}
                    onClick={(e) => {
                      clickHandler(e, footerData.contactUs.twitter);
                    }}
                  >
                    <i className="fa-brands fa-twitter-square fs-5"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row footer-bottom p-2">
            <div className="col-md-3">
              <img
                className="img-fluid"
                src={footerData.image.url}
                alt={footerData.image.alt}
                style={{
                  width: footerData.image.width,
                  height: footerData.image.height,
                }}
              />
            </div>
            <div className="col-md-9 policy-terms">
              <span>{footerData.copyright.text}</span>
              {footerData.copyright.links.map((link, index) => (
                <Link to={`.${link.link}`} key={index}>
                  {link.text}
                </Link>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Footer;
