import React from 'react'

const Calendar = () => {
    return (
        <iframe
            src="https://calui.virtusasystems.com/calendar/tacad-cal-web"
            // src="http://localhost:3001/calendar/tacad-cal-web"
            title='calendar'
            id='calendar'
            loading="lazy"
            seamless
            style={{ position: 'relative', width: '100%', height: '600px' }}
        ></iframe>
    )
}

export default Calendar
